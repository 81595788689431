/**
 * @param {*} state
 * @param {*} action
 */
import {
    GGREPLY_GAME_CREATE_FAILURE,
    GGREPLY_GAME_CREATE_SUCCESS,
    GGREPLY_GAME_DETAIL_FAILURE,
    GGREPLY_GAME_DETAIL_SUCCESS,
    GGREPLY_GAME_EDIT_FAILURE,
    GGREPLY_GAME_EDIT_SUCCESS,
    GOOGLE_REPLY_DETAIL_FAILURE,
    GOOGLE_REPLY_DETAIL_SUCCESS,
    GOOGLE_REPLY_FAILURE,
    GOOGLE_REPLY_SUCCESS,
    GOOGLE_REVIEW_FAILURE,
    GOOGLE_REVIEW_SUCCESS
} from "../containers/GGReply/Actions/GGReplyConst";


const GameReducer = (state = {}, action) => {

    switch (action.type) {
        case GGREPLY_GAME_EDIT_SUCCESS:
        case GGREPLY_GAME_EDIT_FAILURE:
        case GGREPLY_GAME_CREATE_SUCCESS:
        case GGREPLY_GAME_CREATE_FAILURE:
        case GGREPLY_GAME_DETAIL_SUCCESS:
        case GGREPLY_GAME_DETAIL_FAILURE:
        case GOOGLE_REPLY_SUCCESS:
        case GOOGLE_REPLY_FAILURE:
        case GOOGLE_REVIEW_SUCCESS:
        case GOOGLE_REVIEW_FAILURE:
        case GOOGLE_REPLY_DETAIL_SUCCESS:
        case GOOGLE_REPLY_DETAIL_FAILURE:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
};

export {
    GameReducer
}
