import { SERVER_EMS, USER_INFO_EMS } from "../../constans";
import RSAA from "redux-api-middleware/es/RSAA";
import userInfoSave from "../../utils/userInfoSave";

import {
  EMS_USER_FAILURE,
  EMS_USER_REQUEST, EMS_USER_SUCCESS,
} from "./LoginType";

const GetEmsUserAction = (token) => ({
  [RSAA]: {
    endpoint: `${SERVER_EMS}/api/me`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    types: [
      EMS_USER_REQUEST,
      {
        type: EMS_USER_SUCCESS,
        payload: async (action, state, res) => {
          const contentType = res.headers.get("Content-Type");
          if (contentType && ~contentType.indexOf("json")) {
            await res.json().then((json) => {
              userInfoSave(json.data, USER_INFO_EMS);
            });

            return {
              code: 0,
              type: EMS_USER_SUCCESS,
            };
          }
        },
      },
      EMS_USER_FAILURE,
    ],
  },
});

export { GetEmsUserAction };
