import {redirect_page} from '../../utils';
import RSAA from "redux-api-middleware/es/RSAA";
import {SERVER_API_AUTH2} from "../../constans";
import headers from "../../utils/headers";
import {
    LOGOUT_ERROR,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS
} from "../../types";
import Cookies from "universal-cookie";

/**
 * LOGOUT
 */
const logoutAction = () => ({

    [RSAA]: {
        endpoint: `${SERVER_API_AUTH2}/api/auth/logout`,
        method: 'GET',
        headers: (headers()),
        types: [
            LOGOUT_REQUEST,
            {
                type: LOGOUT_SUCCESS,
                payload: (action, state, res) => {
                    //clear all data
                    localStorage.clear();
                    sessionStorage.clear();
                    const cookies = new Cookies();
                    cookies.remove('access_token', {path: '/', secure: true});
                    redirect_page('/');
                },
            },
            {
                type: LOGOUT_ERROR,
                payload: async (action, state, res) => {

                    let message = "";
                    await res.json().then((json) => {
                        message = json.message;
                    });

                    return {
                        "code": 1,
                        "type": LOGOUT_ERROR,
                        "message": message,
                        "data": [],
                    }
                },
            },
        ]
    }
});

export {logoutAction}