export const EMPLOYEE_INDEX_REQUEST = 'EMPLOYEE_INDEX_REQUEST';
export const EMPLOYEE_INDEX_SUCCESS = 'EMPLOYEE_INDEX_SUCCESS';
export const EMPLOYEE_INDEX_ERROR = 'EMPLOYEE_INDEX_ERROR';

export const CON_REPORT_INDEX_REQUEST = 'CON_REPORT_INDEX_REQUEST';
export const CON_REPORT_INDEX_SUCCESS = 'CON_REPORT_INDEX_SUCCESS';
export const CON_REPORT_INDEX_ERROR = 'CON_REPORT_INDEX_ERROR';

export const CUSTOMER_INDEX_REQUEST = 'CUSTOMER_INDEX_REQUEST';
export const CUSTOMER_INDEX_SUCCESS = 'CUSTOMER_INDEX_SUCCESS';
export const CUSTOMER_INDEX_ERROR = 'CUSTOMER_INDEX_ERROR';

export const CON_MESSAGE_INDEX_REQUEST = 'CON_MESSAGE_INDEX_REQUEST';
export const CON_MESSAGE_INDEX_SUCCESS = 'CON_MESSAGE_INDEX_SUCCESS';
export const CON_MESSAGE_INDEX_ERROR = 'CON_MESSAGE_INDEX_ERROR';

export const CONVERSATION_SHOW_REQUEST = 'CONVERSATION_SHOW_REQUEST';
export const CONVERSATION_SHOW_SUCCESS = 'CONVERSATION_SHOW_SUCCESS';
export const CONVERSATION_SHOW_ERROR = 'CONVERSATION_SHOW_ERROR';

export const CON_EMPLOYEE_REPORT_ERROR = 'CON_EMPLOYEE_REPORT_ERROR';
export const CON_EMPLOYEE_REPORT_REQUEST = 'CON_EMPLOYEE_REPORT_REQUEST';
export const CON_EMPLOYEE_REPORT_SUCCESS = 'CON_EMPLOYEE_REPORT_SUCCESS';

export const CON_LATE_MESSAGE_INDEX_REQUEST = 'CON_LATE_MESSAGE_INDEX_REQUEST';
export const CON_LATE_MESSAGE_INDEX_SUCCESS = 'CON_LATE_MESSAGE_INDEX_SUCCESS';
export const CON_LATE_MESSAGE_INDEX_ERROR = 'CON_LATE_MESSAGE_INDEX_ERROR';

export const CON_REPORT_DAILY_ERROR = 'CON_REPORT_DAILY_ERROR';
export const CON_REPORT_DAILY_REQUEST = 'CON_REPORT_DAILY_REQUEST';
export const CON_REPORT_DAILY_SUCCESS = 'CON_REPORT_DAILY_SUCCESS';

export const MESSAGE_INDEX_ERROR = 'MESSAGE_INDEX_ERROR';
export const MESSAGE_INDEX_REQUEST = 'MESSAGE_INDEX_REQUEST';
export const MESSAGE_INDEX_SUCCESS = 'MESSAGE_INDEX_SUCCESS';

export const LATE_MESSAGE_INDEX_ERROR = 'LATE_MESSAGE_INDEX_ERROR';
export const LATE_MESSAGE_INDEX_REQUEST = 'LATE_MESSAGE_INDEX_REQUEST';
export const LATE_MESSAGE_INDEX_SUCCESS = 'LATE_MESSAGE_INDEX_SUCCESS';

