import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  ClearStoreAction,
  GetMeAction,
  LoginPassportAction,
} from "../../../actions";
import { is_login } from "../../../utils";
import { connect } from "react-redux";
import issetFiled from "../../../utils/issetFiled";
import Loading from "../../Loading/Loading";
import { GetMyAuthAction } from "../../../actions/login/GetMyAuthAction";
import { LOGIN_BY_GOOGLE_URL } from "../../../types";
import isTime2Click from "../../../utils/isTime2Click";
import { GetUrlLoginGoogleAction } from "../../../actions/login/GetUrlLoginGoogleAction";
import Version from "../../version";
import { GetMyFbLogChatAction } from "../../../actions/login/GetMyFbLogChatAction";
import { GetMyShortLinkAction } from "../../../actions/login/GetMyShortLinkAction";
import { GetMyFileAction } from "../../../actions/login/GetMyFileAction";
import { GetFBUserLogsAction } from "../../../actions/login/GetFBUserLogsAction";
import { GetMyFbGroupAction } from "../../../actions/login/GetMyFbGroupAction";
import { GetEmsUserAction } from "../../../actions/login/GetEmsUserAction";
import { GetMyCBotAction } from "../../../actions/login/GetMyCBotAction";
import { GetSmsUserAction } from "../../../actions/login/GetSmsUserAction";
import { GetSdkNotifyUserAction } from "../../../actions/login/GetSdkNotifyUserAction";
import { GetEmailUserAction } from "../../../containers/Email/Actions/GetEmailUserAction";
import { GetProjectUserAction } from "../../../containers/Projects/Actions/GetProjectUserAction";

class LoginSimple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_login: is_login(),
    };
  }

  actor() {
    return "LoginSimple";
  }

  handleLoginGoogleClick = async () => {
    if (!isTime2Click(this.actor())) return;
    await this.props.getUrlLoginGoogleAction();
  };

  componentWillMount() {
    if (this.props.user_login.user_name)
      this.setState({ user_name: this.props.user_login.user_name });
    this.props.clearStoreAction();
  }

  async UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { i18n } = this.props;
    const token_type = issetFiled("token_type")
      ? JSON.parse(localStorage.getItem("token_type"))
      : null;
    const access_token = issetFiled("access_token")
      ? JSON.parse(localStorage.getItem("access_token"))
      : null;
    const token = token_type + " " + access_token;

    if (nextProps.user_login.data) {
      if (nextProps.user_login.data.type === LOGIN_BY_GOOGLE_URL) {
        window.open(nextProps.user_login.data.data.url, "_self");
      }
      if (nextProps.user_login.data.code === 0) {
        if (nextProps.user_login.data.type === "login") {
          if (nextProps.user_login.data.code === 0) {
            await this.props.getMyAuthAction(token);
            await this.props.getMeAction(token);
            await this.props.getMyFbLogChatAction(token);
            await this.props.getMyShortLinkAction(token);
            await this.props.getMyFileAction(token);
            await this.props.getMyCBotAction(token);
            await this.props.getFBUserLogsAction(token);
            await this.props.getMyFbGroupAction(token);
            await this.props.getEmsUserAction(token);
            await this.props.getSmsUserAction(token);
            await this.props.getSdkNotifyUserAction(token);
            await this.props.getProjectUserAction(token);
            window.location.href = "/dashboard";
          } else {
            this.setState({
              disabled_btn_login: false,
              message_error: nextProps.user_login.data.message,
            });
          }
        }
      } else
        this.setState({
          disabled_btn_login: false,
          message_error: i18n.t("account_does_not_have_access"),
        });
    }
  }

  loading = () => <Loading />;

  render() {
    const { is_login } = this.state;
    const { i18n } = this.props;
    if (is_login) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card>
                <CardBody>
                  <h1>{i18n.t("Login")}</h1>

                  <Button
                    color="primary"
                    id={"login-google-button"}
                    className="px-4 btn btn-block"
                    type="button"
                    onClick={this.handleLoginGoogleClick}
                  >
                    <i className="fa fa-google" aria-hidden="true">
                      {i18n.t("  Sign in with Google")}
                    </i>
                  </Button>
                  <Version />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_login: state.user_login_reducer,
  };
};

export default connect(mapStateToProps, {
  getMeAction: GetMeAction,
  getMyAuthAction: GetMyAuthAction,
  getMyFbLogChatAction: GetMyFbLogChatAction,
  loginPassportAction: LoginPassportAction,
  clearStoreAction: ClearStoreAction,
  getUrlLoginGoogleAction: GetUrlLoginGoogleAction,
  getMyShortLinkAction: GetMyShortLinkAction,
  getMyFileAction: GetMyFileAction,
  getMyCBotAction: GetMyCBotAction,
  getFBUserLogsAction: GetFBUserLogsAction,
  getMyFbGroupAction: GetMyFbGroupAction,
  getEmsUserAction: GetEmsUserAction,
  getSmsUserAction: GetSmsUserAction,
  getSdkNotifyUserAction: GetSdkNotifyUserAction,
  getEmailUserAction: GetEmailUserAction,
  getProjectUserAction: GetProjectUserAction,
})(LoginSimple);
