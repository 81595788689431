import { USER_INFO_FB_ACTIVITY_LOGS } from "../../constans";
import RSAA from "redux-api-middleware/es/RSAA";
import userInfoSave from "../../utils/userInfoSave";
import { SERVER_FB_NUMBER } from "../../constans/api_config";
import {
  FB_USER_LOGS_FAILURE,
  FB_USER_LOGS_REQUEST,
  FB_USER_LOGS_SUCCESS,
} from "./LoginType";

const GetFBUserLogsAction = (token) => ({
  [RSAA]: {
    endpoint: `${SERVER_FB_NUMBER}/api/user`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    types: [
      FB_USER_LOGS_REQUEST,
      {
        type: FB_USER_LOGS_SUCCESS,
        payload: async (action, state, res) => {
          const contentType = res.headers.get("Content-Type");
          if (contentType && ~contentType.indexOf("json")) {
            await res.json().then((json) => {
              userInfoSave(json.data, USER_INFO_FB_ACTIVITY_LOGS);
            });

            return {
              code: 0,
              type: FB_USER_LOGS_SUCCESS,
            };
          }
        },
      },
      FB_USER_LOGS_FAILURE,
    ],
  },
});

export { GetFBUserLogsAction };
