import { SERVER_API_NOTIFY, USER_INFO_SDK_NOTIFY } from "../../constans";
import RSAA from "redux-api-middleware/es/RSAA";
import userInfoSave from "../../utils/userInfoSave";
import { SDK_NOTIFY_USER_FAILURE, SDK_NOTIFY_USER_REQUEST, SDK_NOTIFY_USER_SUCCESS } from "./LoginType";

const GetSdkNotifyUserAction = (token) => ({
  [RSAA]: {
    endpoint: `${SERVER_API_NOTIFY}/users/me`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    types: [
      SDK_NOTIFY_USER_REQUEST,
      {
        type: SDK_NOTIFY_USER_SUCCESS,
        payload: async (action, state, res) => {
          const contentType = res.headers.get("Content-Type");
          if (contentType && ~contentType.indexOf("json")) {
            await res.json().then((json) => {
              userInfoSave(json.data, USER_INFO_SDK_NOTIFY);
            });

            return {
              code: 0,
              type: SDK_NOTIFY_USER_SUCCESS,
            };
          }
        },
      },
      SDK_NOTIFY_USER_FAILURE,
    ],
  },
});

export { GetSdkNotifyUserAction };
