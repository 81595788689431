
export const SMS_REPORT_LIST_REQUEST = 'SMS_REPORT_LIST_REQUEST';
export const SMS_REPORT_LIST_SUCCESS = 'SMS_REPORT_LIST_SUCCESS';
export const SMS_REPORT_LIST_FAILURE = 'SMS_REPORT_LIST_FAILURE';

export const TEMPLATE_INDEX_REQUEST = 'TEMPLATE_INDEX_REQUEST';
export const TEMPLATE_INDEX_SUCCESS = 'TEMPLATE_INDEX_SUCCESS';
export const TEMPLATE_INDEX_ERROR = 'TEMPLATE_INDEX_ERROR';

export const SMS_SENT_INDEX_ERROR = 'SMS_SENT_INDEX_ERROR';
export const SMS_SENT_INDEX_REQUEST = 'SMS_SENT_INDEX_REQUEST';
export const SMS_SENT_INDEX_SUCCESS = 'SMS_SENT_INDEX_SUCCESS';

