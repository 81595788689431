
export const REPORT_SWITCH_ERROR = 'REPORT_SWITCH_ERROR';
export const REPORT_SWITCH_REQUEST = 'REPORT_SWITCH_REQUEST';
export const REPORT_SWITCH_SUCCESS = 'REPORT_SWITCH_SUCCESS';

export const REPORT_SWITCH_CHECK_ERROR = 'REPORT_SWITCH_CHECK_ERROR';
export const REPORT_SWITCH_CHECK_REQUEST = 'REPORT_SWITCH_CHECK_REQUEST';
export const REPORT_SWITCH_CHECK_SUCCESS = 'REPORT_SWITCH_CHECK_SUCCESS';


export const REPORT_STATUS_RUN = 'run';
export const REPORT_STATUS_STOP = 'stop';

