/**
 |--------------------------------------------------
 | CLIENT CONFIG
 |--------------------------------------------------
 */

import {
  SERVER_API_AUTH2,
  BASE_URL,
  SERVER_API_PLATFORMJK,
  SERVER_API_NOTIFY,
  SERVER_EMAIL_NOTIFY,
  SERVER_GG_STORE_REPLY,
  SERVER_API_FB_BOT,
  SERVER_SHORT_LINK,
  SERVER_FB_NUMBER,
  SERVER_ENV,
  SERVER_FB_GROUP,
  SERVER_SMS_NOTIFY,
  SERVER_TOOL_ORDER,
  SERVER_EMS,
  SERVER_CSI,
  SERVER_API_ACTIONS,
} from "./api_config";

export {
  SERVER_API_AUTH2,
  BASE_URL,
  SERVER_API_PLATFORMJK,
  SERVER_API_NOTIFY,
  SERVER_EMAIL_NOTIFY,
  SERVER_GG_STORE_REPLY,
  SERVER_API_FB_BOT,
  SERVER_SHORT_LINK,
  SERVER_FB_NUMBER,
  SERVER_ENV,
  SERVER_FB_GROUP,
  SERVER_SMS_NOTIFY,
  SERVER_TOOL_ORDER,
  SERVER_CSI,
  SERVER_EMS,
  SERVER_API_ACTIONS,
};

export const EMAIL_FUNTAP = /@(funtap.vn|techlab.ai|9pay.vn|mobgame.mobi)\s*$/;

export const USER_INFO_AUTH2 = "auth2";
export const USER_INFO_EMS = "ems";
export const USER_INFO_FB_LOG_CHAT = "fb_log_chat";
export const USER_INFO_FUNBOT_V2 = "funbot_v2";
export const USER_INFO_SHORT_LINK = "short_link_v2";
export const USER_INFO_FILE = "file_notifun";
export const USER_INFO_CBOT = "cbot_notifun";
export const USER_INFO_FB_ACTIVITY_LOGS = "fb_activity_logs";
export const USER_FB_GROUP = "fb-group";
export const USER_TOOL_ORDER = "tool_order";
export const USER_CSI = "csi";
export const TINY_EDITOR_API_KEY =
  "wutkpy6iz8f6iwp97iywp2tcpylxibzhu245l8f1snydw2qv";
export const USER_INFO_SMS = "sms";
export const USER_INFO_SDK_NOTIFY = "sdk_notify";
export const USER_INFO_EMAIL_NOTIFY = "email_notify";
export const USER_INFO_ACTIONS = "actions";
export const USER_INFO_GG_REPLY = "gg_reply";
