import React, {Component} from 'react';
import {isLogin} from '../../utils';
import {
    Redirect,
} from "react-router-dom";

class CheckIsLogin extends Component {

    canAccessWithoutLogin(){
        let ignoreUri = [
            'auth/google',
            'login/simple',
            'login/interact',
            'register/interact',
        ];

        for(let i = 0;i<ignoreUri.length;i++){
            let uri = ignoreUri[i];
            let indexOf = window.location.href.indexOf(uri);
            if(indexOf > -1) return  true;
        }

        return false;
    }

    render() {
        if (isLogin() || this.canAccessWithoutLogin()) {
            return null;
        }

        return (<Redirect to='/login'/>);
    }
}

export {CheckIsLogin};
