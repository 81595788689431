export const GGREPLY_GAME_REQUEST = 'GGREPLY_GAME_REQUEST';
export const GGREPLY_GAME_SUCCESS = 'GGREPLY_GAME_SUCCESS';
export const GGREPLY_GAME_FAILURE = 'GGREPLY_GAME_FAILURE';

export const GGREPLY_GAME_CREATE_REQUEST = 'GGREPLY_GAME_CREATE_REQUEST';
export const GGREPLY_GAME_CREATE_SUCCESS = 'GGREPLY_GAME_CREATE_SUCCESS';
export const GGREPLY_GAME_CREATE_FAILURE = 'GGREPLY_GAME_CREATE_FAILURE';

export const GGREPLY_GAME_EDIT_REQUEST = 'GGREPLY_GAME_EDIT_REQUEST';
export const GGREPLY_GAME_EDIT_SUCCESS = 'GGREPLY_GAME_EDIT_SUCCESS';
export const GGREPLY_GAME_EDIT_FAILURE = 'GGREPLY_GAME_EDIT_FAILURE';

export const GGREPLY_GAME_DETAIL_REQUEST = 'GGREPLY_GAME_DETAIL_REQUEST';
export const GGREPLY_GAME_DETAIL_SUCCESS = 'GGREPLY_GAME_DETAIL_SUCCESS';
export const GGREPLY_GAME_DETAIL_FAILURE = 'GGREPLY_GAME_DETAIL_FAILURE';

export const GOOGLE_REPLY_REQUEST = 'GOOGLE_REPLY_REQUEST';
export const GOOGLE_REPLY_SUCCESS = 'GOOGLE_REPLY_SUCCESS';
export const GOOGLE_REPLY_FAILURE = 'GOOGLE_REPLY_FAILURE';

export const GOOGLE_REPLY_DETAIL_REQUEST = 'GOOGLE_REPLY_DETAIL_REQUEST';
export const GOOGLE_REPLY_DETAIL_SUCCESS = 'GOOGLE_REPLY_DETAIL_SUCCESS';
export const GOOGLE_REPLY_DETAIL_FAILURE = 'GOOGLE_REPLY_DETAIL_FAILURE';

export const GOOGLE_REVIEW_REQUEST = 'GOOGLE_REVIEW_REQUEST';
export const GOOGLE_REVIEW_SUCCESS = 'GOOGLE_REVIEW_SUCCESS';
export const GOOGLE_REVIEW_FAILURE = 'GOOGLE_REVIEW_FAILURE';

export const GOOGLE_ALL_USER_REQUEST = 'GOOGLE_ALL_USER_REQUEST';
export const GOOGLE_ALL_USER_SUCCESS = 'GOOGLE_ALL_USER_SUCCESS';
export const GOOGLE_ALL_USER_FAILURE = 'GOOGLE_ALL_USER_FAILURE';

export const GOOGLE_ASSIGN_USER_TO_GAME_REQUEST = 'GOOGLE_ASSIGN_USER_TO_GAME_REQUEST';
export const GOOGLE_ASSIGN_USER_TO_GAME_SUCCESS = 'GOOGLE_ASSIGN_USER_TO_GAME_SUCCESS';
export const GOOGLE_ASSIGN_USER_TO_GAME_FAILURE = 'GOOGLE_ASSIGN_USER_TO_GAME_FAILURE';

export const GOOGLE_LIST_GROUP_TELEGRAM_REQUEST = 'GOOGLE_LIST_GROUP_TELEGRAM_REQUEST';
export const GOOGLE_LIST_GROUP_TELEGRAM_SUCCESS = 'GOOGLE_LIST_GROUP_TELEGRAM_SUCCESS';
export const GOOGLE_LIST_GROUP_TELEGRAM_FAILURE = 'GOOGLE_LIST_GROUP_TELEGRAM_FAILURE';

export const GOOGLE_CREATE_GROUP_TELEGRAM_REQUEST = 'GOOGLE_CREATE_GROUP_TELEGRAM_REQUEST';
export const GOOGLE_CREATE_GROUP_TELEGRAM_SUCCESS = 'GOOGLE_CREATE_GROUP_TELEGRAM_SUCCESS';
export const GOOGLE_CREATE_GROUP_TELEGRAM_FAILURE = 'GOOGLE_CREATE_GROUP_TELEGRAM_FAILURE';

export const GOOGLE_ASSIGN_GAME_TO_GROUP_TELEGRAM_REQUEST = 'GOOGLE_ASSIGN_GAME_TO_GROUP_TELEGRAM_REQUEST';
export const GOOGLE_ASSIGN_GAME_TO_GROUP_TELEGRAM_SUCCESS = 'GOOGLE_ASSIGN_GAME_TO_GROUP_TELEGRAM_SUCCESS';
export const GOOGLE_ASSIGN_GAME_TO_GROUP_TELEGRAM_FAILURE = 'GOOGLE_ASSIGN_GAME_TO_GROUP_TELEGRAM_FAILURE';

export const GOOGLE_EDIT_GROUP_TELEGRAM_REQUEST = 'GOOGLE_EDIT_GROUP_TELEGRAM_REQUEST';
export const GOOGLE_EDIT_GROUP_TELEGRAM_SUCCESS = 'GOOGLE_EDIT_GROUP_TELEGRAM_SUCCESS';
export const GOOGLE_EDIT_GROUP_TELEGRAM_FAILURE = 'GOOGLE_EDIT_GROUP_TELEGRAM_FAILURE';

export const GOOGLE_ITEM_GROUP_TELEGRAM_REQUEST = 'GOOGLE_ITEM_GROUP_TELEGRAM_REQUEST';
export const GOOGLE_ITEM_GROUP_TELEGRAM_SUCCESS = 'GOOGLE_ITEM_GROUP_TELEGRAM_SUCCESS';
export const GOOGLE_ITEM_GROUP_TELEGRAM_FAILURE = 'GOOGLE_ITEM_GROUP_TELEGRAM_FAILURE';

export const GOOGLE_DELETE_GROUP_TELEGRAM_REQUEST = 'GOOGLE_DELETE_GROUP_TELEGRAM_REQUEST';
export const GOOGLE_DELETE_GROUP_TELEGRAM_SUCCESS = 'GOOGLE_DELETE_GROUP_TELEGRAM_SUCCESS';
export const GOOGLE_DELETE_GROUP_TELEGRAM_FAILURE = 'GOOGLE_DELETE_GROUP_TELEGRAM_FAILURE';

export const GOOGLE_REMOVE_GAME_TO_GROUP_TELEGRAM_REQUEST = 'GOOGLE_REMOVE_GAME_TO_GROUP_TELEGRAM_REQUEST';
export const GOOGLE_REMOVE_GAME_TO_GROUP_TELEGRAM_SUCCESS = 'GOOGLE_REMOVE_GAME_TO_GROUP_TELEGRAM_SUCCESS';
export const GOOGLE_REMOVE_GAME_TO_GROUP_TELEGRAM_FAILURE = 'GOOGLE_REMOVE_GAME_TO_GROUP_TELEGRAM_FAILURE';

export const GOOGLE_REMOVE_USER_TO_GAME_REQUEST = 'GOOGLE_REMOVE_USER_TO_GAME_REQUEST';
export const GOOGLE_REMOVE_USER_TO_GAME_SUCCESS = 'GOOGLE_REMOVE_USER_TO_GAME_SUCCESS';
export const GOOGLE_REMOVE_USER_TO_GAME_FAILURE = 'GOOGLE_REMOVE_USER_TO_GAME_FAILURE';

export const GOOGLE_REPLY_GET_ME_REQUEST = 'GOOGLE_REPLY_GET_ME_REQUEST';
export const GOOGLE_REPLY_GET_ME_SUCCESS = 'GOOGLE_REPLY_GET_ME_SUCCESS';
export const GOOGLE_REPLY_GET_ME_FAILURE = 'GOOGLE_REPLY_GET_ME_FAILURE';
