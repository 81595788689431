/**
 |--------------------------------------------------
 | DEFAULT TYPE ACTION
 |--------------------------------------------------
 */

// AUTHENTICATION
export const LOGIN_BY_EMAIL = "LOGIN_BY_EMAIL";
export const LOGOUT = "LOGOUT";
export const REGISTER_BY_EMAIL = "REGISTER_BY_EMAIL";
export const REGISTER_BY_GOOGLE = "REGISTER_BY_GOOGLE";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_INFO_FUNBOT = "GET_USER_INFO_FUNBOT";
export const FAILURE_REGISTER_BY_EMAIL = "FAILURE_REGISTER_BY_EMAIL";
export const FAILURE_LOGIN_BY_EMAIL = "FAILURE_LOGIN_BY_EMAIL";
export const FAILURE_TOKEN = "FAILURE_TOKEN";
export const ROLES = "ROLES";
export const GET_ROLES = "GET_ROLES";

export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// CLEAR
export const CLEAR_STORE = "CLEAR_STORE";

export const GET_ME_INBOX_REQUEST = "GET_ME_INBOX_REQUEST";
export const GET_ME_INBOX_SUCCESS = "GET_ME_INBOX_SUCCESS";
export const GET_ME_INBOX_FAILURE = "GET_ME_INBOX_FAILURE";

//GOOGLE
export const LOGIN_BY_GOOGLE_URL = "LOGIN_BY_GOOGLE_URL";
export const FAILURE_LOGIN_BY_GOOGLE_URL = "FAILURE_LOGIN_BY_GOOGLE_URL";
export const REQUEST_LOGIN_BY_GOOGLE_URL = "REQUEST_LOGIN_BY_GOOGLE_URL";

export const REQUEST_LOGIN_BY_GOOGLE = "REQUEST_LOGIN_BY_GOOGLE";
export const FAILURE_LOGIN_BY_GOOGLE = "FAILURE_LOGIN_BY_GOOGLE";
export const LOGIN_BY_GOOGLE = "LOGIN_BY_GOOGLE";

export const LOGIN_BY_GOOGLE_REQUEST = "LOGIN_BY_GOOGLE_REQUEST";
export const LOGIN_BY_GOOGLE_SUCCESS = "LOGIN_BY_GOOGLE_SUCCESS";
export const LOGIN_BY_GOOGLE_FAILURE = "LOGIN_BY_GOOGLE_FAILURE";

// USER DATA
export const USER_DATA_REQUEST = "USER_DATA_REQUEST";
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
export const USER_DATA_FAILURE = "USER_DATA_FAILURE";
export const USER_DATA_DOWNLOAD_REQUEST = "USER_DATA_DOWNLOAD_REQUEST";
export const USER_DATA_DOWNLOAD_SUCCESS = "USER_DATA_DOWNLOAD_SUCCESS";
export const USER_DATA_DOWNLOAD_FAILURE = "USER_DATA_DOWNLOAD_FAILURE";

// SMS NOTIFY
export const EVENT_DATA_DOWNLOAD_REQUEST = "EVENT_DATA_DOWNLOAD_REQUEST";
export const EVENT_DATA_DOWNLOAD_SUCCESS = "EVENT_DATA_DOWNLOAD_SUCCESS";
export const EVENT_DATA_DOWNLOAD_FAILURE = "EVENT_DATA_DOWNLOAD_FAILURE";
export const RESET_SMS_REPORT_REQUEST = "RESET_SMS_REPORT_REQUEST";
export const RESET_SMS_REPORT_SUCCESS = "RESET_SMS_REPORT_SUCCESS";
export const RESET_SMS_REPORT_FAILURE = "RESET_SMS_REPORT_FAILURE";
export const SMS_PERMISSION_REQUEST = "SMS_PERMISSION_REQUEST";
export const SMS_PERMISSION_SUCCESS = "SMS_PERMISSION_SUCCESS";
export const SMS_PERMISSION_FAILURE = "SMS_PERMISSION_FAILURE";
export const SMS_EXPORT_DATA_REQUEST = "SMS_EXPORT_DATA_REQUEST";
export const SMS_EXPORT_DATA_SUCCESS = "SMS_EXPORT_DATA_SUCCESS";
export const SMS_EXPORT_DATA_FAILURE = "SMS_EXPORT_DATA_FAILURE";
export const SMS_USER_LIST_REQUEST = "SMS_USER_LIST_REQUEST";
export const SMS_USER_LIST_SUCCESS = "SMS_USER_LIST_SUCCESS";
export const SMS_USER_LIST_FAILURE = "SMS_USER_LIST_FAILURE";
export const SMS_ABILITY_LIST_REQUEST = "SMS_ABILITY_LIST_REQUEST";
export const SMS_ABILITY_LIST_SUCCESS = "SMS_ABILITY_LIST_SUCCESS";
export const SMS_ABILITY_LIST_FAILURE = "SMS_ABILITY_LIST_FAILURE";

export const SMS_PHONE_LIST_REQUEST = "SMS_PHONE_LIST_REQUEST";
export const SMS_PHONE_LIST_SUCCESS = "SMS_PHONE_LIST_SUCCESS";
export const SMS_PHONE_LIST_FAILURE = "SMS_PHONE_LIST_FAILURE";

// USER DATA USERS
export const USERS_REQUEST = "USERS_REQUEST";
export const USERS_SUCCESS = "USERS_SUCCESS";
export const USERS_FAILURE = "USERS_FAILURE";

// SHORT LINK APPROVAL
export const APPROVAL_TELEGRAM_GROUP_LIST_REQUEST =
  "APPROVAL_TELEGRAM_GROUP_LIST_REQUEST";
export const APPROVAL_TELEGRAM_GROUP_LIST_SUCCESS =
  "APPROVAL_TELEGRAM_GROUP_LIST_SUCCESS";
export const APPROVAL_TELEGRAM_GROUP_LIST_ERROR =
  "APPROVAL_TELEGRAM_GROUP_LIST_ERROR";
export const APPROVAL_TELEGRAM_GROUP_CREATE_REQUEST =
  "APPROVAL_TELEGRAM_GROUP_CREATE_REQUEST";
export const APPROVAL_TELEGRAM_GROUP_CREATE_SUCCESS =
  "APPROVAL_TELEGRAM_GROUP_CREATE_SUCCESS";
export const APPROVAL_TELEGRAM_GROUP_CREATE_ERROR =
  "APPROVAL_TELEGRAM_GROUP_CREATE_ERROR";
export const APPROVAL_TELEGRAM_GROUP_DETAIL_REQUEST =
  "APPROVAL_TELEGRAM_GROUP_DETAIL_REQUEST";
export const APPROVAL_TELEGRAM_GROUP_DETAIL_SUCCESS =
  "APPROVAL_TELEGRAM_GROUP_DETAIL_SUCCESS";
export const APPROVAL_TELEGRAM_GROUP_DETAIL_ERROR =
  "APPROVAL_TELEGRAM_GROUP_DETAIL_ERROR";
export const APPROVAL_TELEGRAM_GROUP_UPDATE_REQUEST =
  "APPROVAL_TELEGRAM_GROUP_UPDATE_REQUEST";
export const APPROVAL_TELEGRAM_GROUP_UPDATE_SUCCESS =
  "APPROVAL_TELEGRAM_GROUP_UPDATE_SUCCESS";
export const APPROVAL_TELEGRAM_GROUP_UPDATE_ERROR =
  "APPROVAL_TELEGRAM_GROUP_UPDATE_ERROR";
export const APPROVAL_TELEGRAM_GROUP_DELETE_REQUEST =
  "APPROVAL_TELEGRAM_GROUP_DELETE_REQUEST";
export const APPROVAL_TELEGRAM_GROUP_DELETE_SUCCESS =
  "APPROVAL_TELEGRAM_GROUP_DELETE_SUCCESS";
export const APPROVAL_TELEGRAM_GROUP_DELETE_ERROR =
  "APPROVAL_TELEGRAM_GROUP_DELETE_ERROR";

//EMS
export const GROUP_LIST_REQUEST = "GROUP_LIST_REQUEST";
export const GROUP_LIST_SUCCESS = "GROUP_LIST_SUCCESS";
export const GROUP_LIST_FAILURE = "GROUP_LIST_FAILURE";

export const GROUP_CREATE_REQUEST = "GROUP_CREATE_REQUEST";
export const GROUP_CREATE_SUCCESS = "GROUP_CREATE_SUCCESS";
export const GROUP_CREATE_FAILURE = "GROUP_CREATE_FAILURE";

export const GROUP_UPDATE_REQUEST = "GROUP_UPDATE_REQUEST";
export const GROUP_UPDATE_SUCCESS = "GROUP_UPDATE_SUCCESS";
export const GROUP_UPDATE_FAILURE = "GROUP_UPDATE_FAILURE";

export const MSG_LIST_REQUEST = "MSG_LIST_REQUEST";
export const MSG_LIST_SUCCESS = "MSG_LIST_SUCCESS";
export const MSG_LIST_FAILURE = "MSG_LIST_FAILURE";
export const MSG_CREATE_REQUEST = "MSG_CREATE_REQUEST";
export const MSG_CREATE_SUCCESS = "MSG_CREATE_SUCCESS";
export const MSG_CREATE_FAILURE = "MSG_CREATE_FAILURE";

export const MSG_SEGMENT_LIST_REQUEST = "MSG_SEGMENT_LIST_REQUEST";
export const MSG_SEGMENT_LIST_SUCCESS = "MSG_SEGMENT_LIST_SUCCESS";
export const MSG_SEGMENT_LIST_FAILURE = "MSG_SEGMENT_LIST_FAILURE";
export const SEGMENT_MSG_DL_LIST_REQUEST = "SEGMENT_MSG_DL_LIST_REQUEST";
export const SEGMENT_MSG_DL_LIST_SUCCESS = "SEGMENT_MSG_DL_LIST_SUCCESS";
export const SEGMENT_MSG_DL_LIST_FAILURE = "SEGMENT_MSG_DL_LIST_FAILURE";
export const MSG_SEGMENT_CREATE_REQUEST = "MSG_SEGMENT_CREATE_REQUEST";
export const MSG_SEGMENT_CREATE_SUCCESS = "MSG_SEGMENT_CREATE_SUCCESS";
export const MSG_SEGMENT_CREATE_FAILURE = "MSG_SEGMENT_CREATE_FAILURE";

export const MSG_SEGMENT_UPDATE_REQUEST = "MSG_SEGMENT_UPDATE_REQUEST";
export const MSG_SEGMENT_UPDATE_SUCCESS = "MSG_SEGMENT_UPDATE_SUCCESS";
export const MSG_SEGMENT_UPDATE_FAILURE = "MSG_SEGMENT_UPDATE_FAILURE";

export const MSG_SEGMENT_DELETE_REQUEST = "MSG_SEGMENT_DELETE_REQUEST";
export const MSG_SEGMENT_DELETE_SUCCESS = "MSG_SEGMENT_DELETE_SUCCESS";
export const MSG_SEGMENT_DELETE_FAILURE = "MSG_SEGMENT_DELETE_FAILURE";

export const CHAT_LIST_REQUEST = "CHAT_LIST_REQUEST";
export const CHAT_LIST_SUCCESS = "CHAT_LIST_SUCCESS";
export const CHAT_LIST_FAILURE = "CHAT_LIST_FAILURE";

export const TELE_USER_UPDATE_REQUEST = "TELE_USER_UPDATE_REQUEST";
export const TELE_USER_UPDATE_SUCCESS = "TELE_USER_UPDATE_SUCCESS";
export const TELE_USER_UPDATE_FAILURE = "TELE_USER_UPDATE_FAILURE";

export const CHAT_UPDATE_REQUEST = "CHAT_UPDATE_REQUEST";
export const CHAT_UPDATE_SUCCESS = "CHAT_UPDATE_SUCCESS";
export const CHAT_UPDATE_FAILURE = "CHAT_UPDATE_FAILURE";

export const SDK_CONFIG_CREATE_REQUEST = "SDK_CONFIG_CREATE_REQUEST";
export const SDK_CONFIG_CREATE_SUCCESS = "SDK_CONFIG_CREATE_SUCCESS";
export const SDK_CONFIG_CREATE_FAILURE = "SDK_CONFIG_CREATE_FAILURE";

export const SDK_CONFIG_LIST_REQUEST = "SDK_CONFIG_LIST_REQUEST";
export const SDK_CONFIG_LIST_SUCCESS = "SDK_CONFIG_LIST_SUCCESS";
export const SDK_CONFIG_LIST_FAILURE = "SDK_CONFIG_LIST_FAILURE";

export const SDK_CONFIG_UPDATE_REQUEST = "SDK_CONFIG_UPDATE_REQUEST";
export const SDK_CONFIG_UPDATE_SUCCESS = "SDK_CONFIG_UPDATE_SUCCESS";
export const SDK_CONFIG_UPDATE_FAILURE = "SDK_CONFIG_UPDATE_FAILURE";

export const SDK_CONFIG_DETAILS_REQUEST = "SDK_CONFIG_DETAILS_REQUEST";
export const SDK_CONFIG_DETAILS_SUCCESS = "SDK_CONFIG_DETAILS_SUCCESS";
export const SDK_CONFIG_DETAILS_FAILURE = "SDK_CONFIG_DETAILS_FAILURE";

export const INBOUND_EMAILS_LIST_REQUEST = "INBOUND_EMAILS_LIST_REQUEST";
export const INBOUND_EMAILS_LIST_SUCCESS = "INBOUND_EMAILS_LIST_SUCCESS";
export const INBOUND_EMAILS_LIST_FAILURE = "INBOUND_EMAILS_LIST_FAILURE";

// PROJECT
export const PROJECT_LIST_REQUEST = "PROJECT_LIST_REQUEST";
export const PROJECT_LIST_SUCCESS = "PROJECT_LIST_SUCCESS";
export const PROJECT_LIST_FAILURE = "PROJECT_LIST_FAILURE";

export const CREATE_PROJECT_REQUEST = "CREATE_PROJECT_REQUEST";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAILURE = "CREATE_PROJECT_FAILURE";

export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAILURE = "UPDATE_PROJECT_FAILURE";

export const DETAIL_PROJECT_REQUEST = "DETAIL_PROJECT_REQUEST";
export const DETAIL_PROJECT_SUCCESS = "DETAIL_PROJECT_SUCCESS";
export const DETAIL_PROJECT_FAILURE = "DETAIL_PROJECT_FAILURE";

export const EMAIL_HIPPO_QUOTA_REQUEST = "EMAIL_HIPPO_QUOTA_REQUEST";
export const EMAIL_HIPPO_QUOTA_SUCCESS = "EMAIL_HIPPO_QUOTA_SUCCESS";
export const EMAIL_HIPPO_QUOTA_FAILURE = "EMAIL_HIPPO_QUOTA_FAILURE";

export const EMAIL_CREATE_HIPPO_QUOTA_REQUEST = "EMAIL_CREATE_HIPPO_QUOTA_REQUEST";
export const EMAIL_CREATE_HIPPO_QUOTA_SUCCESS = "EMAIL_CREATE_HIPPO_QUOTA_SUCCESS";
export const EMAIL_CREATE_HIPPO_QUOTA_FAILURE = "EMAIL_CREATE_HIPPO_QUOTA_FAILURE";

export const EMAIL_IMPORT_VERIFY_REQUEST = "EMAIL_IMPORT_VERIFY_REQUEST";
export const EMAIL_IMPORT_VERIFY_SUCCESS = "EMAIL_IMPORT_VERIFY_SUCCESS";
export const EMAIL_IMPORT_VERIFY_FAILURE = "EMAIL_IMPORT_VERIFY_FAILURE";
