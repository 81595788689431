import {USER_INFO_FILE} from '../../constans';
import RSAA from "redux-api-middleware/es/RSAA";
import userInfoSave from "../../utils/userInfoSave";
import {REACT_APP_SERVER_FILES} from "../../constans/api_config";
import {MY_FILE_FAILURE, MY_FILE_REQUEST, MY_FILE_SUCCESS} from "./LoginType";

const GetMyFileAction = (token) => ({

    [RSAA]: {
        endpoint: `${REACT_APP_SERVER_FILES}/api/user`,
        method: 'GET',
        headers: {"Content-Type": "application/json", "Authorization": token},
        types: [
            MY_FILE_REQUEST,
            {
                type: MY_FILE_SUCCESS,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            userInfoSave(json.data, USER_INFO_FILE);
                        });

                        return {
                            "code": 0,
                            "type": MY_FILE_SUCCESS
                        }
                    }
                }
            },
            MY_FILE_FAILURE
        ]
    }
});

export {GetMyFileAction}