import { SERVER_API_AUTH2 } from "../../constans";
import { LOGIN_BY_EMAIL, FAILURE_LOGIN_BY_EMAIL } from "../../types";
import RSAA from "redux-api-middleware/es/RSAA";
import tokenSaveToLocalStorage from "../../utils/tokenSaveToLocalStorage";
/**
 * LOGIN_BY_EMAIL
 * @param {*} email
 * @param {*} password
 */

const LoginPassportAction = (email, password) => ({
  [RSAA]: {
    endpoint: `${SERVER_API_AUTH2}/oauth/token`,
    method: "POST",
    body: () => {
      let client_id = process.env.REACT_APP_CLIENT_ID;
      let client_secret = process.env.REACT_APP_CLIENT_SECRET;

      const formData = new FormData();
      formData.append("grant_type", "password");
      formData.append("client_id", client_id);
      formData.append("client_secret", client_secret);
      formData.append("username", email.toLowerCase());
      formData.append("password", password);
      formData.append("scope", "");
      return formData;
    },
    types: [
      "REQUEST_LOGIN_BY_EMAIL",
      {
        type: LOGIN_BY_EMAIL,
        payload: async (action, state, res) => {
          const contentType = res.headers.get("Content-Type");
          if (contentType && ~contentType.indexOf("json")) {
            await res.json().then((json) => {
              tokenSaveToLocalStorage(json);
            });

            if (res.status === 200)
              return {
                code: 0,
                type: "login",
              };
          }
        },
      },
      {
        type: FAILURE_LOGIN_BY_EMAIL,
        payload: async (action, state, res) => {
          const contentType = res.headers.get("Content-Type");
          let message = "";
          if (contentType && ~contentType.indexOf("json")) {
            await res.json().then((json) => {
              message = json.message;
            });

            return {
              code: 1,
              type: "login",
              message: message,
            };
          }
        },
      },
    ],
  },
});

export { LoginPassportAction };
