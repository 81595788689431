const en = {
  login: "Login",
  password: "Password",
  register: "Register",
  sign_in_to_your_account: "Sign In to your account",
  register_now: "Register now",
  email_is_required: "Email is required.",
  email_is_incorrect: "Email is incorrect.",
  password_must_be_at_least_6_characters:
    "Password must be at least 10 characters.",
  account_does_not_have_access: "Account does not have access.",
  create_your_account: "Create your account.",
  user_name: "User Name",
  repeat_password: "Repeat password",
  create_account: "Create Account",
  dashboard: "Dashboard",
  admin: "Admin",
  users: "Users",
  roles: "Roles",
  permission: "Permission",
  home: "Home",
  detail: "Detail",
  profile: "Profile",
  search: "Search",
  id: "ID",
  message: "Message",
  image: "Image",
  page_id_from_facebook: "Page ID from SmsNotify",
  status: "Status",
  action: "Action",
  no_data: "No data",
  not_found: "Not found",
  app_name: "App name",
  app_id_from_facebook: "App id from facebook",
  keyword: "Keyword",
  do_you_want_to_delete: "Do you want to delete",
  cancel: "Cancel",
  method: "Method",
  url: "Url",
  registered: "Registered",
  roles_name: "Roles name",
  admin_id: "Admin id",
  client_id: "Client id",
  admin_responded: "Admin responded",
  all: "All",
  true: "True",
  false: "False",
  platform: "Platform",
  admin_started: "Admin started",
  start_message: "Start message",
  last_message: "Last message",
  update: "Update",
  view: "View",
  endpoint_and_press_enter: "Endpoint and press enter",
  keyword_and_press_enter: "Keyword and press enter",
  user_and_press_enter: "User and press enter",
  page_name_is_required: "Page name is required!",
  page_id_from_facebook_is_required: "Page id from facebook is required!",
  edit_keywords_success: "Edit keywords success",
  edit_endpoints_success: "Edit endpoints success",
  delete_endpoints_success: "Delete endpoints success",
  edit_success: "Edit success",
  create_success: "Create success",
  type_keyword_and_press_enter: "Type keyword and press enter",
  submit: "Submit",
  headers: "Headers",
  queries: "Queries",
  settings: "Settings",
  logout: "Logout",
  user_name_is_required: "User name is required!",
  user_name_are_limited_to_6_to_14_characters:
    "User name are limited to 6 to 14 characters!",
  password_is_required: "Password is required!",
  you_need_to_confirm_the_password: "You need to confirm the password!",
  password_does_not_match: "Password does not match!",
  owner_email: "Owner Email",
  kpi_type: "Type",
  kpi_time: "Time",
  game_code: "Game Code",
  value_unit_number: "Number #",
  value_unit_percent: "Percent %",
  value_unit_float: "Float 7.2",
  value_unit_usd: "Money (USD)",
  value_unit_vnd: "Money (VND)",
  employee_name: "Employee Name",
  customer_name: "Customer Name",
  message_count: "Message Count",
  message_60min_miss_count: "Later 1 Hour",
  message_120min_miss_count: "Later 2 Hours",
  message_24hours_miss_count: "Later 24 Hours",
  inbox_report_note_1:
    "- Báo cáo được chia theo ngày. Tức là cùng 1 khách, nếu chat với NV trong 2 ngày sẽ cho ra 2 dòng báo cáo.",
  inbox_report_note_2:
    "- Số bên phải tên NV là số lượng Tin Nhắn mà NV đã gửi cho khách trong ngày đó, tương tự với số cạnh tên Khách.",
  inbox_report_note_3:
    "- Late 1 Hour: là số lượng tin nhắn mà Khách đã gửi, nhưng không được NV phản hồi trong vòng 1 giờ.",
  inbox_report_note_4:
    "- Nếu Khách gửi 'abc', xong gửi tiếp '123' và 1 giờ sau NV vẫn chưa phản hồi thì sẽ tính là 2 tin nhắn bị trễ.",
  employee_report_note_1: "- Cách tính số liệu tương tự với Inbox Report.",
  employee_report_note_2:
    "- Total Customer: là tổng số khách mà NV phải phục vụ, tính theo ngày.",
  employee_report_note_3:
    "- Cùng 1 Khách nhắn tin với 1 NV trong 2 ngày khác nhau thì Total Customers tính là 2.",
  employee_report_note_4:
    "- Nếu khách gửi tin nhắn sau 23 giờ và NV ko trả lời kịp thì không tính là trễ",
  //Short Link
  short_link: "Short Link",
  short_link_users: "Short Link Users",
  full_link: "Full Link",
  creator: "Creator",
  abilities: "Permissions",
  assign: "Assign",
  retract: "Retract",
  tracking: "Tracking Count",
  created_at: "Created At",
  active: "Active",

  // User Data
  uid: "UID",
  phone_number: "Phone",
  group_id: "Group Id",
  admins: "Admins",
  actions: "Actions",
  approval_telegram_group: "Approval Group",
  email: "Email",
  usernames: "Tele Usernames",
  emails: "Emails",
  app_id_from_partner: "App key",
  app_os: "App OS",
  int_app_id_from_partner: "App id tool CMS",
  domain_name: 'Domain name',
  domain: 'Domain',
  title: 'Title',
  service: 'Service',
  status_message: 'Status message',

  ip: 'IP Address',
  forward_ips: 'Access IP Address',
  short_link_id: 'Short Link_ID',
  time: 'Time',
  name: "Name",
  code: "Code",
  alias: "Alias",
  game_type: "Game type",
  time_launch: "Time launch",
};

export { en };
