// Redirect v2
export const SHORT_LINK_LIST_REQUEST = 'SHORT_LINK_LIST_REQUEST';
export const SHORT_LINK_LIST_SUCCESS = 'SHORT_LINK_LIST_SUCCESS';
export const SHORT_LINK_LIST_ERROR = 'SHORT_LINK_LIST_ERROR';
export const SHORT_LINK_CREATE_REQUEST = 'SHORT_LINK_CREATE_REQUEST';
export const SHORT_LINK_CREATE_SUCCESS = 'SHORT_LINK_CREATE_SUCCESS';
export const SHORT_LINK_CREATE_ERROR = 'SHORT_LINK_CREATE_ERROR';
export const SHORT_LINK_EDIT_REQUEST = 'SHORT_LINK_EDIT_REQUEST';
export const SHORT_LINK_EDIT_SUCCESS = 'SHORT_LINK_EDIT_SUCCESS';
export const SHORT_LINK_EDIT_ERROR = 'SHORT_LINK_EDIT_ERROR';
export const SHORT_LINK_DETAIL_REQUEST = 'SHORT_LINK_DETAIL_REQUEST';
export const SHORT_LINK_DETAIL_SUCCESS = 'SHORT_LINK_DETAIL_SUCCESS';
export const SHORT_LINK_DETAIL_ERROR = 'SHORT_LINK_DETAIL_ERROR';

export const SHORT_LINK_PERMISSIONS_ASSIGN_REQUEST = 'SHORT_LINK_PERMISSIONS_ASSIGN_REQUEST';
export const SHORT_LINK_PERMISSIONS_ASSIGN_SUCCESS = 'SHORT_LINK_PERMISSIONS_ASSIGN_SUCCESS';
export const SHORT_LINK_PERMISSIONS_ASSIGN_ERROR = 'SHORT_LINK_PERMISSIONS_ASSIGN_ERROR';

export const SHORT_LINK_PERMISSIONS_RETRACT_REQUEST = 'SHORT_LINK_PERMISSIONS_RETRACT_REQUEST';
export const SHORT_LINK_PERMISSIONS_RETRACT_SUCCESS = 'SHORT_LINK_PERMISSIONS_RETRACT_SUCCESS';
export const SHORT_LINK_PERMISSIONS_RETRACT_ERROR = 'SHORT_LINK_PERMISSIONS_RETRACT_ERROR';

export const SHORT_LINK_PERMISSIONS_LIST_REQUEST = 'SHORT_LINK_PERMISSIONS_LIST_REQUEST';
export const SHORT_LINK_PERMISSIONS_LIST_SUCCESS = 'SHORT_LINK_PERMISSIONS_LIST_SUCCESS';
export const SHORT_LINK_PERMISSIONS_LIST_ERROR = 'SHORT_LINK_PERMISSIONS_LIST_ERROR';

export const SHORT_LINK_GET_LIST_DOMAIN_REQUEST = 'SHORT_LINK_GET_LIST_DOMAIN_REQUEST';
export const SHORT_LINK_GET_LIST_DOMAIN_SUCCESS = 'SHORT_LINK_GET_LIST_DOMAIN_SUCCESS';
export const SHORT_LINK_GET_LIST_DOMAIN_ERROR = 'SHORT_LINK_GET_LIST_DOMAIN_ERROR';

export const SHORT_LINK_GET_ALL_REQUEST = 'SHORT_LINK_GET_ALL_REQUEST';
export const SHORT_LINK_GET_ALL_SUCCESS = 'SHORT_LINK_GET_ALL_SUCCESS';
export const SHORT_LINK_GET_ALL_ERROR = 'SHORT_LINK_GET_ALL_ERROR';

export const TRACKING_SHORT_LINK_REQUEST = 'TRACKING_SHORT_LINK_REQUEST';
export const TRACKING_SHORT_LINK_SUCCESS = 'TRACKING_SHORT_LINK_SUCCESS';
export const TRACKING_SHORT_LINK_ERROR = 'TRACKING_SHORT_LINK_ERROR';