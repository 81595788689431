/**
 * @param {*} state
 * @param {*} action
 */
import {ORDER_GET_ME_SUCCESS} from "../../containers/Order/Actions/OrderConst";

const ToolOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case ORDER_GET_ME_SUCCESS:
            return {
                ...state,
                dataMe: action.payload
            };
        default:
            return state;
    }
};

export {
    ToolOrderReducer
}
