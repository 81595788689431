import { 
    CREATE_PROJECT_SUCCESS, 
    DETAIL_PROJECT_SUCCESS, 
    PROJECT_LIST_SUCCESS, 
    UPDATE_PROJECT_SUCCESS 
} from "../../../types";

/**
 * @param {*} state
 * @param {*} action
 */
const ProjectReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {
        case PROJECT_LIST_SUCCESS:

            let games = action.payload.data.data;
            games.map((games) => {

                let id = games['_id'];
                state[id] = games;
                return true;
            });
            return {...state, action};
        case CREATE_PROJECT_SUCCESS:
            let id = action.payload.data['_id'];
            state[id] = action.payload.data;
            return {...state, action};
        case DETAIL_PROJECT_SUCCESS:
            return {...state, action};
        case UPDATE_PROJECT_SUCCESS:
            return {...state, action};
        default:
            return state;
    }
};

export {ProjectReducer}