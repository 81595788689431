/**
 |--------------------------------------------------
 | CONFIG SERVER & PORT
 |--------------------------------------------------
 */
let BASE_URL = process.env.REACT_APP_BASE_URL;
let SERVER_API_AUTH2 = process.env.REACT_APP_SERVER_API_AUTH2;
let SERVER_API_FB_LOG_CHAT = process.env.REACT_APP_SERVER_API_FB_LOG_CHAT;
let SERVER_API_PLATFORMJK = process.env.REACT_APP_SERVER_API_PLATFORMJK;
let SERVER_API_NOTIFY = process.env.REACT_APP_SERVER_API_NOTIFY;
let SERVER_EMAIL_NOTIFY = process.env.REACT_APP_SERVER_EMAIL_NOTIFY;
let SERVER_SHORT_LINK = process.env.REACT_APP_SERVER_SHORT_LINK;
let REACT_APP_SERVER_FILES = process.env.REACT_APP_SERVER_FILES;
let SERVER_GG_STORE_REPLY = process.env.REACT_APP_SERVER_GOOGLE_REPLY;
let SERVER_API_FB_BOT = process.env.REACT_APP_SERVER_API_FB_BOT;
let SERVER_FB_NUMBER = process.env.REACT_APP_USER_DATA_URL;
let SERVER_EMS_LINK = process.env.REACT_APP_SERVER_EMS_LINK;
let SERVER_ENV = process.env.REACT_APP_SERVER_ENV;
let SERVER_FB_GROUP = process.env.REACT_APP_SERVER_FB_GROUP;
let SERVER_SMS_NOTIFY = process.env.REACT_APP_SERVER_SMS_NOTIFY;
let SERVER_TOOL_ORDER = process.env.REACT_APP_SERVER_TOOL_ORDER;
let SERVER_EMS = process.env.REACT_APP_SERVER_EMS;
let SERVER_CSI = process.env.REACT_APP_SERVER_CSI;
let SERVER_CBOT = process.env.REACT_APP_CBOT_URL;
let SERVER_API_ACTIONS = process.env.REACT_APP_SERVER_API_ACTIONS

let DEFAULT_SHORT_LINK_DOMAIN = process.env.DEFAULT_SHORT_LINK_DOMAIN ?? 're.funtap.vn';

export {
  BASE_URL,
  SERVER_API_AUTH2,
  SERVER_API_FB_LOG_CHAT,
  SERVER_API_PLATFORMJK,
  SERVER_API_NOTIFY,
  SERVER_EMAIL_NOTIFY,
  SERVER_GG_STORE_REPLY,
  SERVER_API_FB_BOT,
  REACT_APP_SERVER_FILES,
  SERVER_SHORT_LINK,
  SERVER_FB_NUMBER,
  SERVER_EMS_LINK,
  SERVER_ENV,
  SERVER_FB_GROUP,
  SERVER_SMS_NOTIFY,
  SERVER_TOOL_ORDER,
  SERVER_EMS,
  SERVER_CSI,
  SERVER_CBOT,
  DEFAULT_SHORT_LINK_DOMAIN,
  SERVER_API_ACTIONS,
};