import {SERVER_FB_GROUP, USER_FB_GROUP} from '../../constans';
import RSAA from "redux-api-middleware/es/RSAA";
import userInfoSave from "../../utils/userInfoSave";
import {MY_FB_GROUP_SUCCESS, MY_GROUP_FAILURE, MY_GROUP_REQUEST} from "./LoginType";

const GetMyFbGroupAction = (token) => ({

    [RSAA]: {
        endpoint: `${SERVER_FB_GROUP}/api/me`,
        method: 'GET',
        headers: {"Content-Type": "application/json", "Authorization": token},
        types: [
            MY_GROUP_REQUEST,
            {
                type: MY_FB_GROUP_SUCCESS,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            userInfoSave(json.data, USER_FB_GROUP);
                        });

                        return {
                            "code": 0,
                            "type": MY_FB_GROUP_SUCCESS
                        }
                    }
                }
            },
            MY_GROUP_FAILURE
        ]
    }
});

export {GetMyFbGroupAction}