
export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAILURE = 'ORDER_LIST_FAILURE';

export const ORDER_LIST_GAME_REQUEST = 'ORDER_LIST_GAME_REQUEST';
export const ORDER_LIST_GAME_SUCCESS = 'ORDER_LIST_GAME_SUCCESS';
export const ORDER_LIST_GAME_FAILURE = 'ORDER_LIST_GAME_FAILURE';

export const ORDER_EDIT_GAME_REQUEST = 'ORDER_EDIT_GAME_REQUEST';
export const ORDER_EDIT_GAME_SUCCESS = 'ORDER_EDIT_GAME_SUCCESS';
export const ORDER_EDIT_GAME_FAILURE = 'ORDER_EDIT_GAME_FAILURE';

export const ORDER_CREATE_GAME_REQUEST = 'ORDER_CREATE_GAME_REQUEST';
export const ORDER_CREATE_GAME_SUCCESS = 'ORDER_CREATE_GAME_SUCCESS';
export const ORDER_CREATE_GAME_FAILURE = 'ORDER_CREATE_GAME_FAILURE';

export const ORDER_LIST_TEAM_REQUEST = 'ORDER_LIST_TEAM_REQUEST';
export const ORDER_LIST_TEAM_SUCCESS = 'ORDER_LIST_TEAM_SUCCESS';
export const ORDER_LIST_TEAM_FAILURE = 'ORDER_LIST_TEAM_FAILURE';

export const ORDER_CREATE_TEAM_REQUEST = 'ORDER_CREATE_TEAM_REQUEST';
export const ORDER_CREATE_TEAM_SUCCESS = 'ORDER_CREATE_TEAM_SUCCESS';
export const ORDER_CREATE_TEAM_FAILURE = 'ORDER_CREATE_TEAM_FAILURE';

export const ORDER_EDIT_TEAM_REQUEST = 'ORDER_EDIT_TEAM_REQUEST';
export const ORDER_EDIT_TEAM_SUCCESS = 'ORDER_EDIT_TEAM_SUCCESS';
export const ORDER_EDIT_TEAM_FAILURE = 'ORDER_EDIT_TEAM_FAILURE';

export const ORDER_ITEM_TEAM_REQUEST = 'ORDER_ITEM_TEAM_REQUEST';
export const ORDER_ITEM_TEAM_SUCCESS = 'ORDER_ITEM_TEAM_SUCCESS';
export const ORDER_ITEM_TEAM_FAILURE = 'ORDER_ITEM_TEAM_FAILURE';

export const ORDER_CREATE_ORDER_REQUEST = 'ORDER_CREATE_ORDER_REQUEST';
export const ORDER_CREATE_ORDER_SUCCESS = 'ORDER_CREATE_ORDER_SUCCESS';
export const ORDER_CREATE_ORDER_FAILURE = 'ORDER_CREATE_ORDER_FAILURE';

export const ORDER_DETAIL_REQUEST = 'ORDER_DETAIL_REQUEST';
export const ORDER_DETAIL_SUCCESS = 'ORDER_DETAIL_SUCCESS';
export const ORDER_DETAIL_FAILURE = 'ORDER_DETAIL_FAILURE';

export const ORDER_EDIT_ORDER_REQUEST = 'ORDER_EDIT_ORDER_REQUEST';
export const ORDER_EDIT_ORDER_SUCCESS = 'ORDER_EDIT_ORDER_SUCCESS';
export const ORDER_EDIT_ORDER_FAILURE = 'ORDER_EDIT_ORDER_FAILURE';

export const ORDER_LIST_PROCESS_ORDER_REQUEST = 'ORDER_LIST_PROCESS_ORDER_REQUEST';
export const ORDER_LIST_PROCESS_ORDER_SUCCESS = 'ORDER_LIST_PROCESS_ORDER_SUCCESS';
export const ORDER_LIST_PROCESS_ORDER_FAILURE = 'ORDER_LIST_PROCESS_ORDER_FAILURE';

export const ORDER_LIST_DONE_ORDER_REQUEST = 'ORDER_LIST_DONE_ORDER_REQUEST';
export const ORDER_LIST_DONE_ORDER_SUCCESS = 'ORDER_LIST_DONE_ORDER_SUCCESS';
export const ORDER_LIST_DONE_ORDER_FAILURE = 'ORDER_LIST_DONE_ORDER_FAILURE';

export const ORDER_LIST_CANCEL_ORDER_REQUEST = 'ORDER_LIST_CANCEL_ORDER_REQUEST';
export const ORDER_LIST_CANCEL_ORDER_SUCCESS = 'ORDER_LIST_CANCEL_ORDER_SUCCESS';
export const ORDER_LIST_CANCEL_ORDER_FAILURE = 'ORDER_LIST_CANCEL_ORDER_FAILURE';

export const ORDER_LIST_REJECT_ORDER_REQUEST = 'ORDER_LIST_REJECT_ORDER_REQUEST';
export const ORDER_LIST_REJECT_ORDER_SUCCESS = 'ORDER_LIST_REJECT_ORDER_SUCCESS';
export const ORDER_LIST_REJECT_ORDER_FAILURE = 'ORDER_LIST_REJECT_ORDER_FAILURE';

export const ORDER_LIST_MANAGER_GAME_REQUEST = 'ORDER_LIST_MANAGER_GAME_REQUEST';
export const ORDER_LIST_MANAGER_GAME_SUCCESS = 'ORDER_LIST_MANAGER_GAME_SUCCESS';
export const ORDER_LIST_MANAGER_GAME_FAILURE = 'ORDER_LIST_MANAGER_GAME_FAILURE';

export const ORDER_GET_ME_REQUEST = 'ORDER_GET_ME_REQUEST';
export const ORDER_GET_ME_SUCCESS = 'ORDER_GET_ME_SUCCESS';
export const ORDER_GET_ME_FAILURE = 'ORDER_GET_ME_FAILURE';

export const ORDER_CHANGE_STOP_RELEASING_GAME_REQUEST = 'ORDER_CHANGE_STOP_RELEASING_GAME_REQUEST';
export const ORDER_CHANGE_STOP_RELEASING_GAME_SUCCESS = 'ORDER_CHANGE_STOP_RELEASING_GAME_SUCCESS';
export const ORDER_CHANGE_STOP_RELEASING_GAME_FAILURE = 'ORDER_CHANGE_STOP_RELEASING_GAME_FAILURE';
