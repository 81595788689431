import {SERVER_API_AUTH2} from '../../constans';
import {FAILURE_LOGIN_BY_GOOGLE_URL, LOGIN_BY_GOOGLE_URL} from '../../types';
import RSAA from "redux-api-middleware/es/RSAA";
import {headers} from "../../utils";

const GetUrlLoginGoogleAction = () => ({
    [RSAA]: {
        endpoint: () => {
            return `${SERVER_API_AUTH2}/oauth/google/url`
        },
        method: 'GET',
        headers: (headers()),
        types: [
            'REQUEST_LOGIN_BY_GOOGLE_URL',
            {
                type: LOGIN_BY_GOOGLE_URL,
                payload: async (action, state, res) => {
                    let data = [];
                    await res.json().then((json) => {
                        data = json.data
                    });

                    return {
                        "code": 0,
                        "id": 0,
                        "type": LOGIN_BY_GOOGLE_URL,
                        "data": data,
                    }
                },
            },
            {
                type: FAILURE_LOGIN_BY_GOOGLE_URL,
                payload: (action, state, res) => {
                    return res.json()
                },
            },
        ]
    }
});

export {GetUrlLoginGoogleAction}
