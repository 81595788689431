import React, {Component} from 'react';

class Version extends Component {

    render() {
        return (
            <span className={"text-muted"}>
                v2.0.15
            </span>
        )
    }
}

export default Version;