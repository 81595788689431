import {
    EVENT_DATA_DOWNLOAD_SUCCESS,
} from "../../types";

/**
 * @param {*} state
 * @param {*} action
 */
const EventDataDownloadReducer = (state = {}, action) => {
    if (action.payload === undefined || action.payload === false) return state;

    switch (action.type) {

        case EVENT_DATA_DOWNLOAD_SUCCESS:
            return {...state, action};
        default:
            return state;
    }
};

export {EventDataDownloadReducer}