import {USER_INFO_SHORT_LINK} from '../../constans';
import RSAA from "redux-api-middleware/es/RSAA";
import userInfoSave from "../../utils/userInfoSave";
import {SERVER_SHORT_LINK} from "../../constans/api_config";
import {MY_SHORT_LINK_FAILURE, MY_SHORT_LINK_REQUEST, MY_SHORT_LINK_SUCCESS} from "./LoginType";

const GetMyShortLinkAction = (token) => ({

    [RSAA]: {
        endpoint: `${SERVER_SHORT_LINK}/api/me`,
        method: 'GET',
        headers: {"Content-Type": "application/json", "Authorization": token},
        types: [
            MY_SHORT_LINK_REQUEST,
            {
                type: MY_SHORT_LINK_SUCCESS,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            userInfoSave(json.data, USER_INFO_SHORT_LINK);
                        });

                        return {
                            "code": 0,
                            "type": MY_SHORT_LINK_SUCCESS
                        }
                    }
                }
            },
            MY_SHORT_LINK_FAILURE
        ]
    }
});

export {GetMyShortLinkAction}