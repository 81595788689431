

export const TEAM_CREATE_SUCCESS = 'TEAM_CREATE_SUCCESS';
export const TEAM_CREATE_ERROR = 'TEAM_CREATE_ERROR';
export const TEAM_CREATE_REQUEST = 'TEAM_CREATE_REQUEST';

export const TEAM_LIST_REQUEST = 'TEAM_LIST_REQUEST';
export const TEAM_LIST_SUCCESS = 'TEAM_LIST_SUCCESS';
export const TEAM_LIST_FAILURE = 'TEAM_LIST_FAILURE';

export const TEAM_MEMBER_LIST_REQUEST = 'TEAM_MEMBER_LIST_REQUEST';
export const TEAM_MEMBER_LIST_SUCCESS = 'TEAM_MEMBER_LIST_SUCCESS';
export const TEAM_MEMBER_LIST_FAILURE = 'TEAM_MEMBER_LIST_FAILURE';

export const TEAM_MEMBER_REMOVE_ERROR = 'TEAM_MEMBER_REMOVE_ERROR';
export const TEAM_MEMBER_REMOVE_REQUEST = 'TEAM_MEMBER_REMOVE_REQUEST';
export const TEAM_MEMBER_REMOVE_SUCCESS = 'TEAM_MEMBER_REMOVE_SUCCESS';

export const TEAM_MEMBER_ADD_ERROR = 'TEAM_MEMBER_ADD_ERROR';
export const TEAM_MEMBER_ADD_REQUEST = 'TEAM_MEMBER_ADD_REQUEST';
export const TEAM_MEMBER_ADD_SUCCESS = 'TEAM_MEMBER_ADD_SUCCESS';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

export const USER_AUTO_CREATE_ERROR = 'USER_AUTO_CREATE_ERROR';
export const USER_AUTO_CREATE_REQUEST = 'USER_AUTO_CREATE_REQUEST';
export const USER_AUTO_CREATE_SUCCESS = 'USER_AUTO_CREATE_SUCCESS';

