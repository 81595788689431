import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import { LoadingSpinner } from "./views/Loading/LoadingSpinner";
import { CheckIsLogin } from "./containers/Users/CheckIsLogin";
import LoginSimple from "./views/Pages/Login/LoginSimple";
import Moment from 'react-moment';

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const GoogleLogin = React.lazy(() =>
  import("./views/Pages/GoogleLogin/GoogleLogin")
);
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

Moment.globalFormat = 'DD/MM/Y H:m:s';

class App extends Component {
  componentDidMount() {
    // Add notify scripts
    const jqueryScript = document.createElement("script");

    jqueryScript.src = "https://code.jquery.com/jquery-3.4.1.min.js";
    jqueryScript.async = true;

    document.body.appendChild(jqueryScript);
  }
  render() {
    return (
      <React.Suspense fallback={""}>
        <ToastContainer />

        <BrowserRouter>
          <CheckIsLogin />
          <LoadingSpinner />
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => (
                <LoginSimple i18n={this.props.i18n} {...props} />
              )}
            />
            <Route
              exact
              path="/login/simple"
              name="Login Page"
              render={(props) => (
                <LoginSimple i18n={this.props.i18n} {...props} />
              )}
            />
            <Route
              exact
              path="/login/interact"
              name="Login Page"
              render={(props) => <Login i18n={this.props.i18n} {...props} />}
            />
            <Route
              exact
              path="/auth/google"
              name="Login Google Page"
              render={(props) => (
                <GoogleLogin i18n={this.props.i18n} {...props} />
              )}
            />
            <Route
              exact
              path="/register/interact"
              name="Register Page"
              render={(props) => <Register i18n={this.props.i18n} {...props} />}
            />

            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => (
                <DefaultLayout i18n={this.props.i18n} {...props} />
              )}
            />
          </Switch>
        </BrowserRouter>
      </React.Suspense>
    );
  }
}

export default App;
