/**
 |--------------------------------------------------
 | ALL REDUCERS
 |--------------------------------------------------
 */
import { combineReducers } from "redux";
import { user_login_reducer } from "./login_register/LoginRegisterReducer";
import { user_info_reducer } from "./GetUserInfoReducer";
import { roles_reducer } from "./RolesReducer";
import { PaginateReducer } from "./PaginateReducer";
import { ApiErrorMessageReducer } from "./ApiErrorMessageReducer";
import { ShortLinkReducer } from "../containers/ShortLink/Reducers/ShortLinkReducer";
import { UsersReducer } from "../containers/Facebook/UserData/Reducers/UsersReducer";
import { ApprovalTelegramReducer } from "./../containers/ShortLink/Reducers/ApprovalTelegram/ApprovalTelegramReducer";
import {ToolOrderReducer} from "./tool_order/ToolOrderReducer";
import {GroupReducer} from "./Ems/Group/GroupReducer";
import {MessageReducer} from "./Ems/Message/MessageReducer";
import {SegmentMsgReducer} from "./Ems/Segment/SegmentMsgReducer";
import {ChatReducer} from "./Ems/Chat/ChatReducer";
import {TeleUserReducer} from "./Ems/TeleUser/TeleUserReducer";
import { EventDataDownloadReducer } from "./SMSNotify/EventDataDownloadReducer";
import { SdkConfigReducer } from "../containers/SdkNotify/Reducer/SdkConfigReducer";
import { ProjectReducer } from "../containers/Projects/Reducers/ProjectReducer";
import {GameReducer} from "./GameReducer";

const reducer = combineReducers({
  user_login_reducer,
  user_info_reducer,
  roles_reducer,
  PaginateReducer,
  ApiErrorMessageReducer,
  ShortLinkReducer,
  UsersReducer,
  ApprovalTelegramReducer,
  ToolOrderReducer,
  MessageReducer,
  GroupReducer,
  SegmentMsgReducer,
  ChatReducer,
  TeleUserReducer,
  EventDataDownloadReducer,
  SdkConfigReducer,
  ProjectReducer,
  GameReducer
});

export default reducer;
