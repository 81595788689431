export const GROUP_LIST_REQUEST = 'GROUP_LIST_REQUEST';
export const GROUP_LIST_SUCCESS = 'GROUP_LIST_SUCCESS';
export const GROUP_LIST_ERROR = 'GROUP_LIST_ERROR';

export const POST_GROUP_LIST_REQUEST = 'POST_GROUP_LIST_REQUEST';
export const POST_GROUP_LIST_SUCCESS = 'POST_GROUP_LIST_SUCCESS';
export const POST_GROUP_LIST_ERROR = 'POST_GROUP_LIST_ERROR';

export const POST_GROUP_COMMENT_ERROR = 'POST_GROUP_COMMENT_ERROR';
export const POST_GROUP_COMMENT_REQUEST = 'POST_GROUP_COMMENT_REQUEST';
export const POST_GROUP_COMMENT_SUCCESS = 'POST_GROUP_COMMENT_SUCCESS';

export const POST_FROM_FACEBOOK_REQUEST = 'POST_FROM_FACEBOOK_REQUEST';
export const POST_FROM_FACEBOOK_SUCCESS = 'POST_FROM_FACEBOOK_SUCCESS';
export const POST_FROM_FACEBOOK_ERROR = 'POST_FROM_FACEBOOK_ERROR';

export const POST_GROUP_CREATE_REQUEST = 'POST_GROUP_CREATE_REQUEST';
export const POST_GROUP_CREATE_SUCCESS = 'POST_GROUP_CREATE_SUCCESS';
export const POST_GROUP_CREATE_ERROR = 'POST_GROUP_CREATE_ERROR';

export const FB_GROUP_CREATE_REQUEST = 'FB_GROUP_CREATE_REQUEST';
export const FB_GROUP_CREATE_SUCCESS = 'FB_GROUP_CREATE_SUCCESS';
export const FB_GROUP_CREATE_ERROR = 'FB_GROUP_CREATE_ERROR';

