import RSAA from "redux-api-middleware/es/RSAA";
import { PROJECT_USER_FAILURE, PROJECT_USER_REQUEST, PROJECT_USER_SUCCESS } from "../../../actions/login/LoginType";
import { SERVER_API_ACTIONS, USER_INFO_ACTIONS } from "../../../constans";
import userInfoSave from "../../../utils/userInfoSave";

const GetProjectUserAction = (token) => ({
  [RSAA]: {
    endpoint: `${SERVER_API_ACTIONS}/api/users/me`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    types: [
      PROJECT_USER_REQUEST,
      {
        type: PROJECT_USER_SUCCESS,
        payload: async (action, state, res) => {
          const contentType = res.headers.get("Content-Type");
          if (contentType && ~contentType.indexOf("json")) {
            await res.json().then((json) => {
              userInfoSave(json.data, USER_INFO_ACTIONS);
            });

            return {
              code: 0,
              type: PROJECT_USER_SUCCESS,
            };
          }
        },
      },
      PROJECT_USER_FAILURE,
    ],
  },
});

export { GetProjectUserAction };
