import issetFiled from "./issetFiled";

/**
 |--------------------------------------------------
 | Headers
 |--------------------------------------------------
 */
export default function headers() {
  const token_type = issetFiled("token_type")
    ? JSON.parse(localStorage.getItem("token_type"))
    : null;
  const access_token = issetFiled("access_token")
    ? JSON.parse(localStorage.getItem("access_token"))
    : null;
  const token = token_type + " " + access_token;
  // const token = token_type + " " + "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjczOGRjNTRlOGJhYWYzZWU3ZjgzM2Y1MWZkODQ3MzIxY2YxYTc0MjU2OTA3YWNkZWVkMGM0MWNiNmE2MWQ2OTgyNTkzNmZkM2NlOGVhMTczIn0.eyJhdWQiOiIyIiwianRpIjoiNzM4ZGM1NGU4YmFhZjNlZTdmODMzZjUxZmQ4NDczMjFjZjFhNzQyNTY5MDdhY2RlZWQwYzQxY2I2YTYxZDY5ODI1OTM2ZmQzY2U4ZWExNzMiLCJpYXQiOjE2NDAwNTcxOTUsIm5iZiI6MTY0MDA1NzE5NSwiZXhwIjoxNjQwMjI5OTk1LCJzdWIiOiIxIiwic2NvcGVzIjpbXSwidXNlciI6eyJpZCI6MSwibmFtZSI6IlBoYW4gVGhpIEh1eWVuIiwiZW1haWwiOiJodXllbnRlc3QxQGZ1bnRhcC52biIsImVtYWlsX3ZlcmlmaWVkX2F0IjpudWxsLCJzdGF0dXMiOjEsImNyZWF0ZWRfYXQiOiIyMDIxLTEyLTIxIDEwOjI0OjU0IiwidXBkYXRlZF9hdCI6IjIwMjEtMTItMjEgMTA6MjQ6NTQiLCJjb21wYW5pZXMiOltdfX0.Gmasxh_hSR05muCfVgVNHtTR6Hnl3LsJ2aPMxganT1I-aW_6_8aAt0DSmFQDeixyTMS_DVIEjNVivSJ-RxiCi9inZphOEfjKw7W2mDsixLcw7xA6J6jRTe4v2V7yEW2H1wxrZlR_uRHYs4R7kDjBOgysZLdXSFiT0fShcRzpZgdjfJNnMSOpcny_mXmrYBBcPjEJW34B5rZLyLLjTrggVgSZT3E9TsZBMPl5sdx0cssSODbZKD6OgPDp3oXckcQTkjYRjHlfCfMZwV9Nt7Io3CdKEuzzhsbNvZIqzT0Lb7okqfE1yWylzHSOE3U2DrO8uUtrBTApG1i_GgSGNLlLvkuHJNP7DUA9ZM8U3zKcxA-IKWG4I3oE44PjU3tbzJC3MQPJtRszNpgMbPm4Nt7gNvZ64FxD0zttpW1_ukVS3UA4oykzCAON-udcq8_i1Xy6I9CwaqY54ulDmIDYOwkWhKt_BMmcah_kwAhOcQ6sgQMVZCxGvKfgiRWfyLYfLf_tUGA00Nmk17hJb1HJ0E5cqkv-CRZffAYHV99TLu0PTtTxp5jaJyzIfQyN4rGE17oXTHrk28j6lg1JP13QFkDcpk8-57BeHTgT1rA70pOalqhdz4RfMprw3dEelpO0zCgVgA2U7o4euAMnyRwI73mP9C_TbEKlRVuptaMSb2mKO9w";
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: token,
  };
}
