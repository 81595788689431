import { CON_REPORT_INDEX_SUCCESS } from "../containers/Facebook/LogChat/Actions/LogChatConst";
import {
  SHORT_LINK_GET_ALL_ERROR,
  SHORT_LINK_GET_ALL_SUCCESS,
  SHORT_LINK_LIST_ERROR,
  SHORT_LINK_LIST_SUCCESS,
  SHORT_LINK_PERMISSIONS_LIST_SUCCESS, TRACKING_SHORT_LINK_SUCCESS,
} from "../containers/ShortLink/ShortLinkConstants";
import { FILE_INDEX_SUCCESS } from "../containers/FileUpload/FileActionType";
import {
  TEAM_LIST_SUCCESS,
  TEAM_MEMBER_LIST_SUCCESS,
} from "../containers/Team/Actions/TeamConst";
import {
  USER_DATA_SUCCESS,
  USER_DATA_FAILURE,
  APPROVAL_TELEGRAM_GROUP_LIST_SUCCESS,
  MSG_SEGMENT_LIST_SUCCESS,
  CHAT_LIST_SUCCESS,
  MSG_LIST_SUCCESS, SMS_USER_LIST_SUCCESS, SDK_CONFIG_LIST_SUCCESS, INBOUND_EMAILS_LIST_SUCCESS, PROJECT_LIST_SUCCESS,
} from "../types";
import { REPLY_SEGMENT_INDEX_SUCCESS } from "../containers/Ems/Segment/Reply/ReplyConst";
import {
  GROUP_LIST_SUCCESS,
  POST_FROM_FACEBOOK_SUCCESS,
  POST_GROUP_COMMENT_SUCCESS,
  POST_GROUP_LIST_SUCCESS,
} from "../containers/FbGroup/FbGroupConstants";
import {EVENT_ACTIVITY_INDEX_SUCCESS} from "../containers/CBot/Event/Actions/EventConst";
import {SMS_SENT_INDEX_SUCCESS, TEMPLATE_INDEX_SUCCESS} from "../containers/SmsNotify/Actions/ReportConst";

/**
 * @param {*} state
 * @param {*} action
 */
const PaginateReducer = (state = {}, action) => {
  if (action.payload === undefined) return { ...state };

  switch (action.type) {
    case CON_REPORT_INDEX_SUCCESS:
    case SHORT_LINK_LIST_SUCCESS:
    case SHORT_LINK_LIST_ERROR:
    case SHORT_LINK_GET_ALL_SUCCESS:
    case SHORT_LINK_GET_ALL_ERROR:
    case FILE_INDEX_SUCCESS:
    case TEAM_LIST_SUCCESS:
    case TEAM_MEMBER_LIST_SUCCESS:
    case REPLY_SEGMENT_INDEX_SUCCESS:
    case TEMPLATE_INDEX_SUCCESS:
    case EVENT_ACTIVITY_INDEX_SUCCESS:
    case SMS_SENT_INDEX_SUCCESS:
      let key = action.type;
      if (state[key] === undefined) state[key] = {};

      state[key] = action.payload.data;
      return { ...state, action };
    case USER_DATA_SUCCESS:
      return { ...state, action };
    case USER_DATA_FAILURE:
    case APPROVAL_TELEGRAM_GROUP_LIST_SUCCESS:
      return { ...state, action };
    case GROUP_LIST_SUCCESS:
      return { ...state, action };
    case POST_GROUP_LIST_SUCCESS:
      return { ...state, action };
    case POST_GROUP_COMMENT_SUCCESS:
      return { ...state, action };
    case POST_FROM_FACEBOOK_SUCCESS:
      return { ...state, action };
    case MSG_SEGMENT_LIST_SUCCESS:
      return { ...state, action };
    case CHAT_LIST_SUCCESS:
      return { ...state, action };
    case MSG_LIST_SUCCESS:
      return {...state, action};
    case SMS_USER_LIST_SUCCESS:
      return {...state, action};
    case SHORT_LINK_PERMISSIONS_LIST_SUCCESS:
      return {...state, action};
    case SDK_CONFIG_LIST_SUCCESS:
      return {...state, action};
    case INBOUND_EMAILS_LIST_SUCCESS:
      return {...state, action};
    case TRACKING_SHORT_LINK_SUCCESS:
    case PROJECT_LIST_SUCCESS:
      return {...state, action};
    default:
      return { ...state };
  }
};

export { PaginateReducer };
