/**
 |--------------------------------------------------
 | DEFAULT TYPE ACTION
 |--------------------------------------------------
 */

export const MY_EMS_KPI_REQUEST = "MY_EMS_KPI_REQUEST";
export const MY_EMS_KPI_SUCCESS = "MY_EMS_KPI_SUCCESS";
export const MY_EMS_KPI_FAILURE = "MY_EMS_KPI_FAILURE";

export const MY_FB_LOG_CHAT_REQUEST = "MY_FB_LOG_CHAT_REQUEST";
export const MY_FB_LOG_CHAT_SUCCESS = "MY_FB_LOG_CHAT_SUCCESS";
export const MY_FB_LOG_CHAT_FAILURE = "MY_FB_LOG_CHAT_FAILURE";

export const MY_SHORT_LINK_REQUEST = "MY_SHORT_LINK_REQUEST";
export const MY_SHORT_LINK_SUCCESS = "MY_SHORT_LINK_SUCCESS";
export const MY_SHORT_LINK_FAILURE = "MY_SHORT_LINK_FAILURE";

export const MY_FILE_FAILURE = "MY_FILE_FAILURE";
export const MY_FILE_REQUEST = "MY_FILE_REQUEST";
export const MY_FILE_SUCCESS = "MY_FILE_SUCCESS";

export const FB_USER_LOGS_FAILURE = "FB_USER_LOGS_FAILURE";
export const FB_USER_LOGS_REQUEST = "FB_USER_LOGS_REQUEST";
export const FB_USER_LOGS_SUCCESS = "FB_USER_LOGS_SUCCESS";

export const EMS_USER_FAILURE = "EMS_USER_FAILURE";
export const EMS_USER_REQUEST = "EMS_USER_REQUEST";
export const EMS_USER_SUCCESS = "EMS_USER_SUCCESS";

export const MY_FB_GROUP_SUCCESS = "MY_FB_GROUP_SUCCESS";
export const MY_GROUP_REQUEST = "MY_GROUP_REQUEST";
export const MY_GROUP_FAILURE = "MY_GROUP_FAILURE";

export const MY_CBOT_SUCCESS = "MY_CBOT_SUCCESS";
export const MY_CBOT_REQUEST = "MY_CBOT_REQUEST";
export const MY_CBOT_FAILURE = "MY_CBOT_FAILURE";

export const SMS_USER_FAILURE = "SMS_USER_FAILURE";
export const SMS_USER_REQUEST = "SMS_USER_REQUEST";
export const SMS_USER_SUCCESS = "SMS_USER_SUCCESS";

export const SDK_NOTIFY_USER_FAILURE = "SDK_NOTIFY_USER_FAILURE";
export const SDK_NOTIFY_USER_REQUEST = "SDK_NOTIFY_USER_REQUEST";
export const SDK_NOTIFY_USER_SUCCESS = "SDK_NOTIFY_USER_SUCCESS";

export const EMAIL_NOTIFY_USER_FAILURE = "EMAIL_NOTIFY_USER_FAILURE";
export const EMAIL_NOTIFY_USER_REQUEST = "EMAIL_NOTIFY_USER_REQUEST";
export const EMAIL_NOTIFY_USER_SUCCESS = "EMAIL_NOTIFY_USER_SUCCESS";

export const PROJECT_USER_FAILURE = "PROJECT_USER_FAILURE";
export const PROJECT_USER_REQUEST = "PROJECT_USER_REQUEST";
export const PROJECT_USER_SUCCESS = "PROJECT_USER_SUCCESS";
