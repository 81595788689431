import {USER_INFO_FB_LOG_CHAT} from '../../constans';
import RSAA from "redux-api-middleware/es/RSAA";
import userInfoSave from "../../utils/userInfoSave";
import {SERVER_API_FB_LOG_CHAT} from "../../constans/api_config";
import {MY_FB_LOG_CHAT_FAILURE, MY_FB_LOG_CHAT_REQUEST, MY_FB_LOG_CHAT_SUCCESS} from "./LoginType";

const GetMyFbLogChatAction = (token) => ({

    [RSAA]: {
        endpoint: `${SERVER_API_FB_LOG_CHAT}/api/user`,
        method: 'GET',
        headers: {"Content-Type": "application/json", "Authorization": token},
        types: [
            MY_FB_LOG_CHAT_REQUEST,
            {
                type: MY_FB_LOG_CHAT_SUCCESS,
                payload: async (action, state, res) => {
                    const contentType = res.headers.get('Content-Type');
                    if (contentType && ~contentType.indexOf('json')) {
                        await res.json().then((json) => {
                            userInfoSave(json.data, USER_INFO_FB_LOG_CHAT);
                        });

                        return {
                            "code": 0,
                            "type": MY_FB_LOG_CHAT_SUCCESS
                        }
                    }
                }
            },
            MY_FB_LOG_CHAT_FAILURE
        ]
    }
});

export {GetMyFbLogChatAction}