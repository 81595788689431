import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import { is_access_token_expired_action } from '../actions';
import redirect_page from './redirect_page';

/**
|--------------------------------------------------
| CHECK USER LOGIN
|--------------------------------------------------
*/
export default function is_login() {
    try { //TODO: remove this method asap
        const access_token = localStorage.getItem('access_token');
        const token_type = localStorage.getItem('token_type');
        const roles = localStorage.getItem('roles');

        if (access_token && is_access_token_expired_action(jwt_decode(access_token).exp)) {
            //clear all data
            localStorage.clear();
            sessionStorage.clear();
            const cookies = new Cookies();
            cookies.remove('access_token', {path: '/', secure: true});
            redirect_page('/login');
        }

        return access_token !== null && token_type !== null && roles !== null;
    } catch (error) {
        return false;
    }
}
