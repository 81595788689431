import { USER_INFO_SMS } from "../../constans";
import RSAA from "redux-api-middleware/es/RSAA";
import userInfoSave from "../../utils/userInfoSave";
import { SERVER_SMS_NOTIFY } from "../../constans/api_config";
import {
  SMS_USER_FAILURE,
  SMS_USER_REQUEST,
  SMS_USER_SUCCESS,
} from "./LoginType";

const GetSmsUserAction = (token) => ({
  [RSAA]: {
    endpoint: `${SERVER_SMS_NOTIFY}/api/me`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    types: [
      SMS_USER_REQUEST,
      {
        type: SMS_USER_SUCCESS,
        payload: async (action, state, res) => {
          const contentType = res.headers.get("Content-Type");
          if (contentType && ~contentType.indexOf("json")) {
            await res.json().then((json) => {
              userInfoSave(json.data, USER_INFO_SMS);
            });

            return {
              code: 0,
              type: SMS_USER_SUCCESS,
            };
          }
        },
      },
      SMS_USER_FAILURE,
    ],
  },
});

export { GetSmsUserAction };
